<template>
  <certificates
    :loading="loading"
    :isManufacturer ="true" 
    :certificate="certificate"
    :certificatesHistory="certificatesHistory"
    :certificateHistoryTotal="certificateHistoryTotal"
    :activeCertificateGuid="activeCertificateGuid"
    :manager="manager"
    :pdfHref="pdfHref"
    :isCertificationProcess="isCertificationProcess"
    :certificationRequests="certificationRequests"
    @approve-certificate="onApprovedCertification"
    @reject-certificate="onRejectCertification"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { Certificates } from '@/components'

export default {
  name: "ManufacturerCertificates",components: { Certificates },
  data() {
    return {
      loading: false,
      pdfHref: ""
    }
  },
  methods: {
    ...mapActions('certificates', [
      "manufacturerVerify",
      "fetchManufacturerCertificate",
      "fetchManufacturerCertificatesHistory",
      "isManufacturerInCertificationProcess",
      "getManufacturerNewIssuanceCertificateRequest",
      "createManufacturerCertificateByRequestId",
      "rejectManufacturerCertificateByRequestId"
    ]),

    onApprovedCertification(id) {
      this.loading = true;
      this.createManufacturerCertificateByRequestId(id).then(() => {
        this.loading = false;
        this.fetchAllData()
      });
    },
    onRejectCertification(id) {
      this.loading = true;
      this.rejectManufacturerCertificateByRequestId(id).then(() => {
        this.loading = false;
        this.fetchAllData()
      });
    },

    fetchAllData() {
      this.isManufacturerInCertificationProcess(this.partnerId)
      this.getManufacturerNewIssuanceCertificateRequest(this.partnerId)
      this.fetchManufacturerCertificate(this.partnerId).then(() => {      
        this.pdfHref = this.certificate ? `${process.env.VUE_APP_API}/manufacturerscertificates/pdf/${this.certificate.key}` : null;
      });
      this.fetchManufacturerCertificatesHistory({id: this.partnerId, addressId: this.$route.params.id });
    }
  },
  computed: {
    ...mapState({partnerId: state => state.auth.user.partnerId}),
    ...mapState('certificates', {
      isManufacturerConfirmed: state => state.confirmed,
      isCertificationProcess: state => state.isInCertificationProcess,
      certificationRequests: state => state.newIssuanceCertificationRequest,
      certificate: state => state.certificate,
      certificatesHistory: state => state.certificatesHistory,
      certificateHistoryTotal: state => state.certificateHistoryTotal,
      activeCertificateGuid: state => state.activeCertificateGuid,
      manager: state => state.manufacturerManager
    })
  },
  created() {
    this.manufacturerVerify().then(() => {
      if (!this.isManufacturerConfirmed) this.$router.push("/manufacturer/verify");
      else {
        this.fetchAllData()
      }
    });
    
  },
}

</script>

<style lang="scss" scoped></style>